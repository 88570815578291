
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { EventBuss } from '@/types/global'
import { isValidNumberAmount } from '@/utils'
import {
    Emitter,
    PropType,
    TableLimit,
    computed,
    defineComponent,
    inject,
    ref
} from 'vue'
import { iconX } from '@/components/icon'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    emits: ['close'],
    props: {
        gameType: {
            type: Number as PropType<number>,
            default: () => 1
        }
    },
    components: {
        'icon-x': iconX
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const message = ref('')
        const chipValue = ref('')
        const { t } = useI18n()

        const limit = computed((): TableLimit => store.getters['limit'])

        return { message, chipValue, close, confirm, t, limit }
    }
})
