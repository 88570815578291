
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'
import LimitDialog from '@/components/dialogs/Limit.vue'
import { EventBuss } from '@/types/global'
import {
    ChangeTableEvent,
    Emitter,
    PropType,
    TableLimit,
    computed,
    defineComponent,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    ref
} from 'vue'
import { iconMenu, iconHome } from '@/components/icon'
import { useRoute, useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import switchTable from '@/components/game/SwitchTable.vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import { AppMutation } from '@/store/types'

export default defineComponent({
    components: {
        'icon-home': iconHome,
        // 'icon-setting': iconSetting,
        // 'icon-curency': iconCurency,
        // 'icon-user': iconCurency,
        'switch-table': switchTable,
        'icon-menu': iconMenu,
        'limit-dialog': LimitDialog
    },
    props: {
        desk: {
            default: -1,
            type: Number as PropType<number>
        },
        gameType: {
            default: -1,
            type: Number as PropType<number>
        },
        xian: {
            default: -1,
            type: Number as PropType<number>
        }
    },
    setup(props) {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const { t } = useI18n()
        const showMobileSwitchDesk = ref(false)
        const deskNoticeKey = ref(`pc-notice-${new Date().getTime()}`)
        const showSwitchTable = ref(false)
        const shoeAndJu = ref('')
        const gType = ref(1)
        const showLimitInfo = ref(false)

        onBeforeMount(() => {
            if (props.desk <= 0 || props.xian <= 0 || props.gameType <= 0) {
                router.push({ name: ROUTES.LOBY })
            }

            // setup listeners
            emitter.on(EventBuss.WINDOW_RESIZED, onWindowResize)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.WINDOW_RESIZED, onWindowResize)
        })

        const handleSessionExpired = () => {
            console.log('Expired session')
            emitter.emit(EventBuss.TOAST_MESSAGE, t('prompt.sessionexpired'))
            store.commit(AppMutation.CLEAR_ALL)
            router.push({ name: ROUTES.SERVER_SELECTION })
        }

        const showLimit = (g: number) => {
            showLimitInfo.value = true
            gType.value = g
        }

        const toggleFullScreen = () => {
            emitter.emit(EventBuss.REQUEST_FULL_SCREEN)
        }

        const handleSwitchTable = (e: ChangeTableEvent) => {
            if (route.name !== ROUTES.GAME_DEFAULT) {
                router.push({ name: ROUTES.GAME_DEFAULT, query: e })
            } else {
                // replace
                router.replace({ query: e })
            }

            showSwitchTable.value = false
        }

        const onWindowResize = () => {
            deskNoticeKey.value = `pc-notice-${new Date().getTime()}`
        }
        const showDialog = (name: DialogBoxName) => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const goLoby = () => {
            router.push({ name: ROUTES.LOBY })
        }
        const hideSwitchTable = () => {
            showSwitchTable.value = false
        }

        const tableLimit = computed(() => {
            if (limit.value) return `${limit.value.Min}-${limit.value.Max}`

            return '0-0'
        })

        const user = computed(() => store.getters['user'])
        const coin = computed(() => store.getters['coin'])
        const limit = computed((): TableLimit => store.getters['limit'])
        const isFull = computed(() => store.getters['isfullScreen'])

        const tableName = computed((): string => {
            if (props.desk <= 0 || props.xian <= 0 || props.gameType <= 0) {
                return ''
            }

            let gameName = ''

            if (props.gameType === 1)
                gameName = t('baccaratmap.name', [props.desk])
            else if (props.gameType === 2)
                gameName = t('dragontigermap.name', [props.desk])
            else if (props.gameType === 3)
                gameName = t('niu.name', [props.desk])

            return gameName
        })

        const deviceOrientation = computed(
            (): 'landscape' | 'portrait' => store.getters['deviceOrientation']
        )

        return {
            gType,
            isFull,
            deviceOrientation,
            limit,
            coin,
            user,
            tableName,
            showMobileSwitchDesk,
            showSwitchTable,
            hideSwitchTable,
            showDialog,
            goLoby,
            showLimit,
            shoeAndJu,
            deskNoticeKey,
            tableLimit,
            showLimitInfo,
            handleSessionExpired,
            handleSwitchTable,
            t,
            toggleFullScreen
        }
    }
})
