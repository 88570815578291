import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-070fe7ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "switch-table-root" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "menu-dp" }
const _hoisted_4 = { class: "list-container" }
const _hoisted_5 = { class: "list-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow_down = _resolveComponent("icon-arrow-down")!
  const _component_icon_x = _resolveComponent("icon-x")!
  const _component_default_item = _resolveComponent("default-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "label",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.showMenu = !_ctx.showMenu), ["stop"]))
        }, _toDisplayString(_ctx.currentLabel), 1),
        _createElementVNode("div", {
          class: "arrow",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMenu = !_ctx.showMenu))
        }, [
          _createVNode(_component_icon_arrow_down, { color: '#99834c' })
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["options", { active: _ctx.showMenu }])
        }, [
          _createElementVNode("div", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeTab(_ctx.GameType.BACCARAT)))
          }, _toDisplayString(_ctx.t('baccarat.name')), 1),
          _createElementVNode("div", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeTab(_ctx.GameType.DRAGON_TIGER)))
          }, _toDisplayString(_ctx.t('dragontiger.name')), 1),
          _createElementVNode("div", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeTab(_ctx.GameType.DEAL_FIRST)))
          }, _toDisplayString(_ctx.t('menu.dealfirst')), 1)
        ], 2)
      ]),
      _createVNode(_component_icon_x, {
        class: "close",
        onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["stop"]))
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.desks, (i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "item",
            key: `pc-switch-item-${i}`
          }, [
            _createVNode(_component_default_item, {
              activetable: _ctx.activeDesk,
              "active-tab": _ctx.activeTab,
              mdata: i,
              onSelectTable: _ctx.handleOnSelectTable,
              onEnterTable: _ctx.handleEnterTable
            }, null, 8, ["activetable", "active-tab", "mdata", "onSelectTable", "onEnterTable"])
          ]))
        }), 128))
      ])
    ])
  ]))
}