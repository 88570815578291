
import { useStore } from '@/store'
import DefaultSwitchItem from './DefaultSwitchItem.vue'
import {
    ChangeTableEvent,
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeUnmount,
    onMounted,
    ref
} from 'vue'
import { AppActions, AppMutation } from '@/store/types'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import { getValueByKey, getValueOfKeyValue } from '@/utils/api'
import { Desk } from '@/types/Desk'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import { iconArrowDown, iconX } from '@/components/icon'
import axios, { CancelTokenSource } from 'axios'

export default defineComponent({
    emits: ['session-expired', 'enter-table', 'close'],
    components: {
        'default-item': DefaultSwitchItem,
        'icon-arrow-down': iconArrowDown,
        'icon-x': iconX
    },
    setup(props, { emit }) {
        const router = useRouter()
        const { t } = useI18n()
        const emitter = inject('emitter') as Emitter
        const store = useStore()

        const activeDesk = ref(-1)

        const showMenu = ref(false)

        const PULL_INTERVAL = 3000
        let serviceTimeOut: boolean | number | any = false
        let failureCounter = 0

        const desks = ref<Desk[]>([])

        let lobyAxiosSource: CancelTokenSource

        const GameType = {
            BACCARAT: 4,
            DRAGON_TIGER: 2,
            DEAL_FIRST: 5
        }

        const activeTab = ref(GameType.BACCARAT)

        onMounted(() => {
            const urlParams = new URLSearchParams(window.location.search)
            let gType = parseInt(urlParams.get('gameType') || '4')

            if (gType) {
                activeTab.value = gType
            }

            loadData()
        })

        onBeforeUnmount(() => {
            stopPullingData()
        })

        const loadData = (_forceReload = false) => {
            if (!_forceReload) store.commit(AppMutation.SHOW_LOADING)

            if (typeof lobyAxiosSource === 'undefined')
                lobyAxiosSource = axios.CancelToken.source()

            const _params = {
                ...OtherApiParams,
                act: ACT.MULTIPLE_DESK_INFO,
                sessionID: token.value,
                username: user.value,
                gameType: activeTab.value
            }

            store
                .dispatch(AppActions.FETCH_DATA_C_TOKEN, {
                    params: _params,
                    token: lobyAxiosSource
                })
                .then((response: string) => {
                    failureCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)

                    const err = getValueByKey(response, 'err')
                    if (err) {
                        // expire session kick out
                        emit('session-expired')
                    } else {
                        desks.value = Desk.ParseList(response)
                        if (response.includes('$UserPoint=')) {
                            const _userPointValuePair =
                                response.split('$')[desks.value.length]
                            const _coin = parseInt(
                                getValueOfKeyValue(_userPointValuePair) ?? '0'
                            )
                            store.commit(AppMutation.SET_COIN, _coin)
                        }

                        serviceTimeOut = setTimeout(() => {
                            loadData(true)
                        }, PULL_INTERVAL)
                    }
                })
                .catch((e) => {
                    if (!axios.isCancel(e)) {
                        failureCounter += 1
                        if (!_forceReload) {
                            store.commit(AppMutation.HIDE_LOADING)
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                t('prompt.networkerror')
                            )
                        }

                        if (failureCounter > 3) {
                            // stop pulling service
                            stopPullingData()
                            emitter.emit(
                                EventBuss.TOAST_MESSAGE,
                                t('prompt.networkerror')
                            )
                            store.commit(AppMutation.CLEAR_ALL)
                            router.push({ name: ROUTES.LOGIN })
                            return
                        }

                        serviceTimeOut = setTimeout(() => {
                            loadData(true)
                        }, PULL_INTERVAL)
                    }
                })
        }

        const stopPullingData = () => {
            if (typeof lobyAxiosSource !== 'undefined') {
                lobyAxiosSource.cancel()
                lobyAxiosSource = axios.CancelToken.source()
            }

            if (typeof serviceTimeOut === 'number') {
                clearTimeout(serviceTimeOut)
                serviceTimeOut = false
            }
        }

        const handleOnSelectTable = (e: Desk) => {
            if (activeDesk.value === e.tableNo) activeDesk.value = -1
            else activeDesk.value = e.tableNo
        }

        const handleEnterTable = (e: ChangeTableEvent) => {
            e.gameType = activeTab.value
            emit('enter-table', e)
        }

        const changeTab = (tab: number) => {
            showMenu.value = false
            if (activeTab.value !== tab) {
                stopPullingData()
                desks.value = []
                activeTab.value = tab

                store.commit(AppMutation.SHOW_LOADING)

                setTimeout(() => {
                    loadData()
                }, 1000)
            }
        }

        const user = computed(() => store.getters['user'])
        const token = computed(() => store.getters['token'])

        const currentLabel = computed(() => {
            if (activeTab.value === GameType.BACCARAT) return t('baccarat.name')
            else if (activeTab.value === GameType.DRAGON_TIGER)
                return t('dragontiger.name')
            else if (activeTab.value === GameType.DEAL_FIRST)
                return t('menu.dealfirst')

            return ''
        })

        return {
            currentLabel,
            GameType,
            activeTab,
            desks,
            activeDesk,
            showMenu,
            changeTab,
            t,
            handleOnSelectTable,
            handleEnterTable
        }
    }
})
